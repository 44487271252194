import React, { useEffect } from "react"
import { connect } from 'react-redux'

import { formatHours } from "../../functions/utils"

const Credit = ({ credit, passe, futur }) => {

    return (
        <div className="credit">

            <div className="credit">
                Nombre d'heures disponibles:<strong>{formatHours(credit - passe - futur, true)}<span>/{formatHours(credit, true)}</span></strong>
                {passe !== 0 && <p>Déjà effectuées: <em>{formatHours(passe, true)}</em></p>}
                {futur !== 0 && <p>A venir: <em>{formatHours(futur, true)}</em></p>}
            </div>
        </div>
    )
}

const mapToProps = state => {
    return {
        credit: state.me.credit_heures,
        passe: state.passe,
        futur: state.futur
    }
}


export default connect(mapToProps, null)(Credit)
