
import { connect } from 'react-redux'
import Connexion from './pages/Connexion'
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom"

import Nav from './Nav'
import Calendar from './pages/Calendrier/Calendar'
import Reset from './pages/Reset'
import Profil from './pages/Profil/Profil'


import './App.scss'

const Structure = ({ deconnexion }) => {
  return (
    <div>
      <Nav />
      <section>
        <Outlet />
        <button className='deconnexion' onClick={deconnexion}>Deconnexion</button>
      </section>
    </div>
  )
}

const App = ({ isLog, deconnexion }) => {
  if (!isLog) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Connexion />} >
            <Route path="*" element={<p>Page non trouvée</p>} />
          </Route>
          <Route path="/resetPass" element={<Reset />} />
        </Routes>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Structure deconnexion={deconnexion} />} >
            <Route index element={<Calendar />} />
            <Route path="profil" element={<Profil />} />
            <Route path="*" element={<Calendar />} />
          </Route>
        </Routes>
      </BrowserRouter>
    )

  }
}

const mapToProps = state => {
  return {
    isLog: state.isLog
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deconnexion: () => dispatch({ type: `DECONNEXION` })
  }
}


export default connect(mapToProps, mapDispatchToProps)(App)
