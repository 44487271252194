import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Outlet, useLocation, Link } from 'react-router-dom'

import MILKY from './../images/MilkyW.png'

import Switch from './../components/Switch'

import './Connexion.scss'

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search])
}

const Connexion = ({ connexion, }) => {

    const query = useQuery()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [badLogin, setBadLogin] = useState(query.get("bad-login") === '1')
    const [newMdp, setNewMdp] = useState(query.get("newMdp") === "true")
    const [showMdp, setShowMdp] = useState(false)

    const [reservation, setReservation] = useState(query.get("reservation") === "1")
    const [avant, setAvant] = useState(query.get("avant") === "1")
    const [annule, setAnnule] = useState(query.get("annule") === "1")
    const [liberation, setLiberation] = useState(query.get("liberation") === "1")

    function _connect() {
        connexion(email, password)
    }

    useEffect(() => {
        console.log("bad login: ", badLogin)
    }, [badLogin])

    useEffect(() => {
        console.log("New mdp: ", newMdp)
    }, [newMdp])

    return (
        <div className='connexion'>
            <section>
                    {/* <Outlet /> */}
                    <img src={MILKY} />
                    <h2>Espace élève</h2>
                    {badLogin && <div className='bad'>e-mail ou mot de passe <br />incorrect</div>}
                    {newMdp && <div className='new'>Si vous êtes inscrit à l'auto-école, vous allez recevoir un nouveau mot de passe<br /><em>Si vous ne le recevez pas regardez dans vos indésirables</em></div>}
                    {reservation && <div className='new'>Votre demande a bien été prise en compte<br /><em>Vous ne recevrez plus d'email lorsque vous réservez une leçon</em></div>}
                    {avant && <div className='new'>Votre demande a bien été prise en compte<br /><em>Vous ne recevrez plus d'email 24h avant une leçon</em></div>}
                    {annule && <div className='new'>Votre demande a bien été prise en compte<br /><em>Vous ne recevrez plus d'email lorsque vous annulez une leçon</em></div>}
                    {liberation && <div className='new'>Votre demande a bien été prise en compte<br /><em>Vous ne recevrez plus d'email lorsqu'un créneau se libère</em></div>}

                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder='e-mail' />
                    <input type={showMdp ? "text" : "password"} value={password} onChange={e => setPassword(e.target.value)} placeholder='mot de passe' />
                    <Switch initiale={showMdp} label="Afficher le mot de passe" devientOn={() => setShowMdp(true)} devientOff={() => setShowMdp(false)} />
                    <Link to="/resetPass" className='forgotPassword'>Créer un nouveau mot de passe</Link>
                    <button onClick={() => _connect()}>Je me connecte</button>
            </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        connexion: (email, password) => dispatch({ type: `CONNEXION`, email, password }),
    }
}


export default connect(null, mapDispatchToProps)(Connexion)