import React, { useState, useEffect } from 'react'

import { Jour, Hours } from './../functions/utils'

import CROSS from './../pictos/cross.svg'

import './flottant.scss'

const Flottant = ({ label, validate, close }) => {

    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1)
        }, 50)
    }, [])

    function fermer() {
        setOpacity(0)
        setTimeout(() => {
            close()
        }, 300)
    }

    return (
        <div className='flottant' style={{ opacity }}>
            <div className='cross'>
                <img src={CROSS} onClick={fermer} />
            </div>
            <p className={`label ${label.color==='red' ? "saumon" : ""}`} >{label.text}</p>
            {label.hDebTs && label.hFinTs && <p className='date'>le {Jour(label.hDebTs)}<br />de {Hours(label.hDebTs)} à {Hours(label.hFinTs)}</p>}
            {label.validate ? <div className='buttons'>
                <button className='saumon' onClick={fermer}>{label.color !== "red" ? "Annuler" : "Retour"}</button>
                <button onClick={validate}>{label.color !== "red" ? "Valider ": "Ok"}</button>
            </div> :
            <div className='buttons'>
                <button onClick={fermer} className={label.color === "red" ? "saumon" : ""}>J'ai compris</button>
            </div>
            }
        </div>
    )
}

export default Flottant