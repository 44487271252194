import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { TimestampToDayMonthYear, Hours, Jour } from '../../functions/utils'
import ChangeMdp from './ChangeMdp'
import Switch from './../../components/Switch'

import './profil.scss'

const Profil = ({ me, email_avant, email_reserve, email_annule, email_liberation, getMyLessons, lessons, del, calendrier, getMyExams, examens }) => {

    const [changeMdp, setChangeMdp] = useState(false)
    const [now, setNow] = useState(new Date())

    useEffect(() => {
        getMyLessons()
        getMyExams()
    }, [calendrier])

    useEffect(()=>{
        console.log("examens: ", examens)
    },[examens])

    return (
        <div className='mon-profil'>
            <ChangeMdp ouvert={changeMdp} fermer={() => setChangeMdp(false)} />
            <header>
                <h1>{me.prenom} {me.nom}</h1>
                <h2>{me.email}</h2>
                <div
                    className='changeMdp'
                    onClick={() => setChangeMdp(true)}
                >
                    Changer mon mot de passe</div>
            </header>
            {examens.length > 0 && <section className='vos-examens'>
                <h1>{examens.length > 1 ? "Mes examens du permis de conduire" : "Mon examens du permis de conduire"}</h1>
                <div className='listeExamens'>
                    <ul>
                    {examens.map(exam=>
                        <li key={exam.id} className={exam.date < Math.floor(now.getTime() / 1000) ? "past" : "futur"}>
                            Le {Jour(exam.date)} à <strong>{Hours(exam.date)}</strong>
                            {exam.commentaire && exam.commentaire.length > 0 && <span><br/>{exam.commentaire}</span>}
                        </li>
                        )}
                    </ul>
                </div>
            </section>
            }
            {lessons.length > 0 && <section className='vos-lecons'>
                <h1>Mes leçons de conduite ({lessons.length})
                    <span>Pour réserver une leçon,<br />rendez-vous sur la page <Link to="/">Calendrier</Link></span>
                </h1>
                <div className='listeLessons'>
                    {lessons.map(lesson =>
                        <div key={lesson.id} className={lesson.isFutur ? "futur" : lesson.status === 1 ? "past" : "absent"}>
                            <p>
                                <strong>{Hours(lesson.debut)}</strong>
                                à <strong>{Hours(lesson.fin)}</strong>
                                le {Jour(lesson.debut)}
                                {!lesson.isFutur &&
                                    lesson.status === 2 ?
                                    <div className='absent'>Vous avez été absent(e) à cette leçon</div> :
                                    <div >{lesson.prenom} {lesson.nom}</div>
                                }
                            </p>
                            {lesson.canDel && <button className='saumon creux' onClick={() => del(lesson.debut, lesson.fin, me.agence_id)}>Annuler</button>}
                        </div>
                    )}
                </div>
            </section>}
            <section className='vos-donnees'>
                <h1>Mes données</h1>
                <article>
                    <p>{me.prenom} {me.nom}</p>
                    <p className='email'>Inscrit depuis le {TimestampToDayMonthYear(me.date_inscription)}</p>
                    <p>{me.numRue} {me.rueEleve} - {me.codeP} {me.villeEleve}</p>
                    <p className='tel'>{me.telEleve}</p>
                    <div className='infosSupp'>
                        <p>Je conduis en <br /><strong>{me.permis_bm === 1 ? "Boite manuelle" : "Boite automatique"}</strong></p>
                        <p>Ma conduite:<br />
                            <strong>{
                                me.type_conduite === 0 ? "Classique" :
                                    me.type_conduite === 1 ? "Supervisée" :
                                        me.type_conduite === 2 ? "Accompagnée" : "Perfectionnement"
                            }</strong>
                        </p>
                        <p>Mon financement:<br />
                            <strong>{
                                me.type_financement === 0 ? "Financement personnel" :
                                    me.type_financement === 1 ? "Permis à 1€" :
                                        me.type_financement === 2 ? "Financeurs sociaux" :
                                            me.type_financement === 3 ? "CPF" : "CFA"
                            }</strong>
                        </p>
                    </div>
                </article>
            </section>

            <section className='votre-agence'>
                <h1>Mon agence</h1>
                <article>
                    <p>{me.nom_agence}</p>
                    <p>{me.numero_rue} {me.rue} - {me.code_postale} {me.ville}</p>
                    <p className='tel'>{me.telephone}</p>
                    <p className='email'>{me.email_agence}</p>
                </article>
            </section>
            <section className='vos-parametres'>
                <h1>Mes paramètres</h1>
                <Switch
                    label={"Recevoir un e-mail quand j'ai réservé une leçon"}
                    initiale={me.email_reserve === 1}
                    devientOn={() => email_reserve(1)}
                    devientOff={() => email_reserve(0)}
                />
                <Switch
                    label={"Recevoir un e-mail 24h avant une leçon"}
                    initiale={me.email_avant === 1}
                    devientOn={() => email_avant(1)}
                    devientOff={() => email_avant(0)}
                />
                <Switch
                    label={"Recevoir un e-mail quand j'annule une leçon"}
                    initiale={me.email_annule === 1}
                    devientOn={() => email_annule(1)}
                    devientOff={() => email_annule(0)}
                />
                <Switch
                    label={"Recevoir un e-mail si un créneau se libère"}
                    initiale={me.email_liberation === 1}
                    devientOn={() => email_liberation(1)}
                    devientOff={() => email_liberation(0)}
                />
            </section>
        </div>
    )
}

const mapToProps = state => {
    return {
        me: state.me,
        lessons: state.lessons || [],
        calendrier: state.calendrier,
        examens: state.examens || []
    }
}

const mapDispatchToProps = dispatch => {
    return {
        email_avant: (value) => dispatch({ type: `SET_EMAIL_AVANT`, value }),
        email_reserve: (value) => dispatch({ type: `SET_EMAIL_RESERVE`, value }),
        email_annule: (value) => dispatch({ type: `SET_EMAIL_ANNULE`, value }),
        email_liberation: (value) => dispatch({ type: `SET_EMAIL_LIBERATION`, value }),
        getMyLessons: () => dispatch({ type: `GET_MY_LESSONS` }),
        getMyExams: () => dispatch({ type: `GET_MY_EXAMS` }),
        del: (hDeb, hFin, agenceId) => dispatch({ type: `SELECT_CRENEAU`, hDeb, hFin, agenceId }),
    }
}


export default connect(mapToProps, mapDispatchToProps)(Profil)