import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Outlet, } from 'react-router-dom'

import MILKY from './../images/MilkyW.png'

import './Connexion.scss'

const Reset = ({ reset }) => {

    const [email, setEmail] = useState("")
    const [isEmail, setIsEmail] = useState(true)

    useEffect(() => {
        setIsEmail((/^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,6}$/).test(email))
    }, [email])


    function validate() {
        if (isEmail) {
            reset(email)
        }
    }

    return (
        <div className='connexion'>
            <section>
                <img src={MILKY} />
                <p>Merci de saisir votre adresse e-mail afin de ré-initialiser votre mot de passe</p>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder='e-mail'/>
                <button onClick={() => validate()} style={{opacity: isEmail ? 1 : 0.5}}>Valider</button>
            </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        reset: (email) => dispatch({ type: `RESET_PWD`, email }),
    }
}

export default connect(null, mapDispatchToProps)(Reset)