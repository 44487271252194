import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'

import Colonne from "./Colonne"

import Credit from "./Credit"
import Header from "./Header"

import './calendrier.scss'

const jour = 60 * 60 * 24 * 3 * 1000

const Calendar = ({ wantToKnow, calendrier, agence_id, canAffiche }) => {

    const [currentDate, setcurrentDate] = useState(0) //C'est un timestamp
    const [nowTs, setNowTs] = useState(new Date())

    useEffect(() => {
        console.log("Calendrier: ", calendrier)
    }, [calendrier])

    useEffect(() => { console.log({ canAffiche }) }, [canAffiche])

    useEffect(() => {
        const date = new Date()
        if (date.getDay() === 0) {
            setcurrentDate(date.getTime() + jour)
            return
        }
        setcurrentDate(date.getTime())
    }, [])

    useEffect(() => {
        if (currentDate === 0) return
        wantToKnow(agence_id, currentDate)
    }, [currentDate])

    function prev() {
        const d = new Date(currentDate)
        if (d.getDay() === 3) {
            setcurrentDate(e => e - (jour * 2))
        } else {
            setcurrentDate(e => e - jour)
        }
    }

    function next() {
        const d = new Date(currentDate)
        if (d.getDay() === 4) {
            setcurrentDate(e => e + (jour * 2))
        } else {
            setcurrentDate(e => e + jour)
        }
    }

    if (canAffiche) {
        return (
            <div className="page_calendrier">
                <Credit />
                <Header next={next} prev={prev} currentDate={currentDate} />
                <button onClick={() => { wantToKnow(agence_id, currentDate) }}>Actualiser</button>
                <div className="lignes">
                    <div className="colonnes">
                        {calendrier.map(colonne =>
                            <Colonne key={colonne.timestamp} colonne={colonne} isMatin={true} agenceId={agence_id} nowTs={Math.floor(nowTs.getTime() / 1000)} />
                        )}
                    </div>
                    <div className="colonnes">
                        {calendrier.map(colonne =>
                            <Colonne key={colonne.timestamp} colonne={colonne} isMatin={false} agenceId={agence_id} nowTs={Math.floor(nowTs.getTime() / 1000)} />
                        )}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="page_calendrier">
                <Credit />
                <Header  prev={prev} currentDate={currentDate} />
                <button onClick={() => { wantToKnow(agence_id, currentDate) }}>Actualiser</button>
                <section className="non-dispo"><p>Calendrier bientôt disponible</p></section>
            </div>)
    }
}

const mapToProps = state => {
    return {
        agence_id: state.me.agence_id,
        calendrier: state.calendrier,
        canAffiche: state.canAffiche
    }
}

const mapDispatchToProps = dispatch => {
    return {
        wantToKnow: (agenceId, timestamp) => dispatch({ type: `WANT_TO_KNOW`, agenceId, timestamp }),
    }
}


export default connect(mapToProps, mapDispatchToProps)(Calendar)
