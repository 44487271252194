import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import CROSS from './../../pictos/cross.svg'

const ChangeMdp = ({ ouvert, fermer, send }) => {

    const [newMdp, setNewMdp] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [min, setMin] = useState(false)
    const [maj, setMaj] = useState(false)
    const [chi, setChi] = useState(false)
    const [car, setCar] = useState(false)
    const [goodForLaunch, setGoodForLaunch] = useState(false)


    useEffect(() => {

        if ((/[a-z]/).test(newMdp)) {
            setMin(newMdp.match(/[a-z]/g).length >= 4)
        } else {
            setMin(false)
        }

        if ((/[A-Z]/).test(newMdp)) {
            setMaj(newMdp.match(/[A-Z]/g).length >= 2)
        } else {
            setMaj(false)
        }

        if ((/[1-9]/).test(newMdp)) {
            setChi(newMdp.match(/[1-9]/g).length >= 3)
        } else {
            setChi(false)
        }

        if ((/\W/).test(newMdp)) {
            setCar(newMdp.match(/\W/g).length >= 2)
        } else {
            setCar(false)
        }

    }, [newMdp, confirmation])

    useEffect(() => {
        if (newMdp === confirmation && min && maj && chi && car) {
            setGoodForLaunch(true)
        } else {
            setGoodForLaunch(false)
        }
    }, [newMdp, confirmation, min, maj, chi, car])

    function valider() {
        if (!goodForLaunch){
            return
        }
        send(newMdp)
        setTimeout(() => {
            fermer()
            setNewMdp("")
            setConfirmation("")
            setMin(false)
            setMaj(false)
            setChi(false)
            setCar(false)
            setGoodForLaunch(false)
        }, 1500)
    }

    return (
        <div
            className='windowChangeMdp'
            style={{
                transform: `translateY(${ouvert ? "0" : `${-850}px`})`,
                opacity: ouvert ? 1 : 0
            }}
        >
            <div onClick={fermer} className='cross'><img src={CROSS} /></div>
            <section>
                <input
                    type="text"
                    placeholder='Nouveau mot de passe'
                    value={newMdp}
                    onChange={e => setNewMdp(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Confirmer le mot de passe'
                    value={confirmation}
                    onChange={e => setConfirmation(e.target.value)}
                />
                <div>
                    <p className={min ? "check" : ""}><span style={{ opacity: min ? 1 : 0 }} />Contient au moins 4 minuscules</p>
                    <p className={maj ? "check" : ""}><span style={{ opacity: maj ? 1 : 0 }} />Contient au moins 2 majuscules</p>
                    <p className={chi ? "check" : ""}><span style={{ opacity: chi ? 1 : 0 }} />Contient au moins 3 chiffres</p>
                    <p className={car ? "check" : ""}><span style={{ opacity: car ? 1 : 0 }} />Contient au moins 2 caractères particuliers</p>
                </div>
                <input
                    style={{
                        opacity: goodForLaunch ? 1 : 0.2,
                        cursor: goodForLaunch ? "pointer" : "default"
                    }}
                    type="submit"
                    value="Valider"
                    className='submit'
                    onClick={valider}
                />
            </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        send: (password) => dispatch({ type: `SET_PASSWORD`, password }),
    }
}


export default connect(null, mapDispatchToProps)(ChangeMdp)