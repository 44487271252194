const reducer = (state = { isLog: false, calendrier: [] }, action) => {

    const newState = { ...state }

    if (action.type === "CONNEXION_ASYNC") {
        newState.isLog = action.isLog
        newState.me = action.me
        newState.agences = action.agences
    }

    if (action.type === "WANT_TO_KNOW_ASYNC") {
        newState.calendrier = [...action.calendrier]
    }

    if (action.type === "SET_HEURES_ASYNC") {
        newState.passe = action.heures.passees
        newState.futur = action.heures.futur
    }

    if (action.type === "VIDE_PROGRAMME") {
        newState.programme = []
    }

    // if (action.type === "MES_LECONS_ASYNC") {
    //     newState.lecons = action.lecons
    // }

    if (action.type === "SET_EMAIL_AVANT_ASYNC") {
        const me = { ...newState.me }
        me.email_avant = action.value
        newState.me = me
    }

    if (action.type === "SET_EMAIL_ANNULE_ASYNC") {
        const me = { ...newState.me }
        me.email_annule = action.value
        newState.me = me
    }

    if (action.type === "SET_EMAIL_RESERVE_ASYNC") {
        const me = { ...newState.me }
        me.email_reserve = action.value
        newState.me = me
    }

    if (action.type === "SET_EMAIL_LIBERATION_ASYNC") {
        const me = { ...newState.me }
        me.email_liberation = action.value
        newState.me = me
    }

    if (action.type === "GET_MY_LESSONS_ASYNC") {
        newState.lessons = action.lecons
    }

    if (action.type === "GET_MY_EXAMS_ASYNC") {
        newState.examens = action.examens
    }


    if (action.type === "SET_CAN_AFFICHE") {
        console.log("Can affiche ici^^")
        newState.canAffiche = action.canAffiche
    }


    return newState
}

export default reducer