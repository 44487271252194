import React, { useEffect, useState } from 'react'

import { moisComplets } from '../../functions/utils'

import ARROW from './../../pictos/arrow.svg'

const Header = ({ next, prev, currentDate }) => {

    const [date, setDate] = useState(new Date(currentDate))

    useEffect(()=>{
        setDate(new Date(currentDate))
    },[currentDate])

    return (
        <div className='header'>
            <div className='arrow p' onClick={prev}>
                <img src={ARROW} />
            </div>
            <h2>
                {moisComplets(date.getMonth())} {date.getFullYear()}
            </h2>
            <div className='arrow n' onClick={next}>
                <img src={ARROW} />
            </div>
        </div>
    )
}

export default Header