const months = ['jan', 'fev', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'dec']

export function jours(i) {
    const j = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
    return j[i]
}

export function mois(i) {
    const m = ["jan", "fev", "mar", "avr", "mai", "juin", "juil", "aout", "sept", "oct", "nov", "dec"]
    return m[i]
}

export function moisComplets(i) {
    const m = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
    return m[i]
}

export function Hours(timestamp) {
    const date = new Date(timestamp * 1000)
    const heure = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    return `${heure}:${minute}`
}

export function Jour(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const week = jours(date.getDay())
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${week} ${jour} ${mois} ${date.getUTCFullYear()}`
}

export function timestampZero(objDate) {
    const jour = objDate.getDate()
    const mois = objDate.getMonth()
    const annee = objDate.getFullYear()
    const date = new Date(annee, mois, jour)
    return date.getTime()
}

export function TimestampToDayMonthYear(timestamp) {
    const date = new Date(timestamp * 1000)
    const mois = months[date.getMonth()]
    const jour = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${jour} ${mois} ${date.getUTCFullYear()}`
}

export function formatHours(float, bool){
    if(Number.isInteger(float)){
        return `${float}${bool ? 'h' : ''}`
    }else{
        return `${Math.floor(float)}h30`
    }
}