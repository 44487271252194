import React, { useState } from 'react'
import { connect } from 'react-redux'
import { jours, mois } from '../../functions/utils'

import Flottant from '../../components/Flottant'

const classStatus = (i) => {
    if (i === 0) {
        return "dispo"
    } else if (i === 1) {
        return "reserve"
    } else {
        return "indispo"
    }
}

const Colonne = ({ colonne, isMatin, select, agenceId, nowTs }) => {

    const [date, setDate] = useState(new Date(colonne.timestamp * 1000))
    const [labelFlottant, setLabelFlottant] = useState(false)

    function _select(hDebTs, hFinTs, agenceId, status) {
        // console.log("Je demande à réserver une leçon, données suivantes: ", hDebTs, hFinTs, agenceId, status)
        if (hDebTs < nowTs) {
            // console.log("Le créneau est déjà passé")
            return
        } else if (status === 0) {
            // console.log("Le créneau est dispo: ", Jour(hDebTs), Hours(hDebTs), " à ", Hours(hFinTs))
            setLabelFlottant({ text: 'Vous allez réserver une leçon le: ', hDebTs, hFinTs, validate: true })
        } else if (status === 1) {
            if (hDebTs - nowTs < 3600 * 48) {
                // console.log("Le début de la leçon est dans moins de 48h, je ne peux pas l'annuler")
                setLabelFlottant({
                    text: 'Rappel : Le délai d’annulation d’une heure de conduite est au minimum de 48H. (voir réf contrat Art. 8)\nMerci de bien vouloir nous adresser un mail afin de nous en informer et de nous faire suivre un justificatif pour votre absence.\nagencechartrons@milkyway-autoecole.com',
                    validate: false, color: "red"
                })
            } else {
                // console.log("Je vais annuler ma leçon")
                setLabelFlottant({ text: 'Vous allez annuler cette leçon: ', hDebTs, hFinTs, validate: true, color: "red" })
            }
        } else {
            // console.log("Le créneau est déjà réservé")
            return
        }
    }

    function validate(hDebTs, hFinTs) {
        select(hDebTs, hFinTs, agenceId)
        setTimeout(() => {
            setLabelFlottant(false)
        }, 150)
    }



    return (
        <div className='colonne'>
            {isMatin ? <header>
                <h2>
                    {jours(date.getDay())}
                </h2>
                <h3>
                    {date.getDate()} {mois(date.getMonth())}
                </h3>
            </header> :
                <div className='separation' />

            }
            <section>
                {labelFlottant &&
                    <Flottant
                        label={labelFlottant}
                        close={() => setLabelFlottant(false)}
                        validate={() => validate(labelFlottant.hDebTs, labelFlottant.hFinTs,)}
                    />
                }
                <div>
                    {isMatin ?
                        colonne.horaires.matin.map(({ hDeb, hFin, hDebTs, hFinTs, status }, i) =>
                            <article key={i} className={classStatus(status)} onClick={() => _select(hDebTs, hFinTs, agenceId, status)} style={{ opacity: hDebTs < nowTs ? "0.5" : "1" }}>
                                <div>
                                    {Number.isInteger(hDeb) ? `${hDeb}:00` : `${parseInt(hDeb)}:30`}
                                </div>
                                -
                                <div>{Number.isInteger(hFin) ? `${hFin}:00` : `${parseInt(hFin)}:30`}</div>
                            </article>
                        ) :
                        colonne.horaires.aprem.map(({ hDeb, hFin, hDebTs, hFinTs, status }, i) =>
                            <article key={i} className={classStatus(status)} onClick={() => _select(hDebTs, hFinTs, agenceId, status)} style={{ opacity: hDebTs < nowTs ? "0.5" : "1" }}>
                                <div>
                                    {Number.isInteger(hDeb) ? `${hDeb}:00` : `${parseInt(hDeb)}:30`}
                                </div>
                                -
                                <div>{Number.isInteger(hFin) ? `${hFin}:00` : `${parseInt(hFin)}:30`}</div>
                            </article>
                        )
                    }
                </div>
            </section>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        select: (hDeb, hFin, agenceId) => dispatch({ type: `SELECT_CRENEAU`, hDeb, hFin, agenceId }),
    }
}


export default connect(null, mapDispatchToProps)(Colonne)