import '@babel/polyfill'
import { put, takeEvery, call } from 'redux-saga/effects'
import { apiCall, logOut } from './apiCall'

function* connexion(email, password) {
    let { me, agences } = yield call(apiCall, "POST", "/login", { email, password })
    // console.log("me: ", me)
    yield put({ type: 'CONNEXION_ASYNC', isLog: true, me, agences })
}

function* deconnexion() {
    let { logout } = yield call(logOut)
    yield put({ type: 'CONNEXION_ASYNC', isLog: !logout })
    window.location.href = ("/")
}

function* wantToKnow(timestamp, agenceId) {
    // console.log("Want to know")
    let { calendrier, heures, canAffiche } = yield call(apiCall, "POST", "/want-to-know", { timestamp: timestamp / 1000, agenceId })
    // console.log("Calendrier: ", calendrier)
    yield put({ type: 'WANT_TO_KNOW_ASYNC', calendrier })
    yield put({ type: 'SET_HEURES_ASYNC', heures })
    yield put({ type: 'SET_CAN_AFFICHE', canAffiche })
}

function* emailAvant(value) {
    let data = yield call(apiCall, "POST", "/email_avant", { value })
    yield put({ type: 'SET_EMAIL_AVANT_ASYNC', value: data.value })
}

function* emailAnnule(value) {
    let data = yield call(apiCall, "POST", "/email_annule", { value })
    yield put({ type: 'SET_EMAIL_ANNULE_ASYNC', value: data.value })
}

function* emailReserve(value) {
    let data = yield call(apiCall, "POST", "/email_reserve", { value })
    yield put({ type: 'SET_EMAIL_RESERVE_ASYNC', value: data.value })
}

function* emailLiberation(value) {
    let data = yield call(apiCall, "POST", "/email_liberation", { value })
    yield put({ type: 'SET_EMAIL_LIBERATION_ASYNC', value: data.value })
}

function* setPassword(password) {
    yield call(apiCall, "POST", "/new_password", { password })
}

function* selectCreneau(hDeb, hFin, agenceId) {
    let { status } = yield call(apiCall, "POST", "/creneau_action", { hDeb, hFin, agenceId })
    console.log("Retour status:", status)
    console.log("10 -> La réservation a bien été effectuée")
    console.log("11 -> La date est antérieure à la date courante, elle ne peut pas être réservée")
    console.log("20 -> La réservation a bien été annulée")
    console.log("21 -> La réservation est antérieure à la date courante, elle ne peut pas être annulée")
    console.log("22 -> La lecon est dans moins de 48h, elle ne peut pas être annulée")

    if (status === 10 || status === 20) {
        yield call(wantToKnow, hDeb * 1000, agenceId)
    } else if (status === 11) {
        alert("Vous ne pouvez pas réserver un créneau déjà passé")
    } else if (status === 21) {
        alert("La réservation est déjà passé, elle ne peut pas être annulée")
    } else if (status === 22) {
        alert("Vous ne pouvez pas annuler une réservation à moins de 48h de celle ci")
    } else if (status === -1) {
        alert("Ce créneau demandé vient d'être réservé\nMerci d'en choisir un autre")
        yield call(wantToKnow, hDeb * 1000, agenceId)
    } else {
        console.log("Status ne correspond à rien")
    }
}

function* getMyLessons() {
    // console.log("Depuis saga, quelles sont mes leçons? :)")
    let { lecons } = yield call(apiCall, "GET", "/getMyLessons")
    yield put({ type: 'GET_MY_LESSONS_ASYNC', lecons })
}

function* getMyExams() {
    let { examens } = yield call(apiCall, "GET", "/getMyExams")
    yield put({ type: 'GET_MY_EXAMS_ASYNC', examens })
}

function* reset(email) {
    console.log(email, " veut ré-initialiser son password depuis saga")
    let retour = yield call(apiCall, "POST", "/resetPWD", { email })
    if (retour) {
        window.location.href = ('/?newMdp=true')
    }
}



export default function* asyncCalls() {
    yield takeEvery('CONNEXION', ({ email, password }) => connexion(email, password))
    yield takeEvery('DECONNEXION', () => deconnexion())
    yield takeEvery('WANT_TO_KNOW', ({ timestamp, agenceId }) => wantToKnow(timestamp, agenceId))
    yield takeEvery('SET_EMAIL_AVANT', ({ value }) => emailAvant(value))
    yield takeEvery('SET_EMAIL_ANNULE', ({ value }) => emailAnnule(value))
    yield takeEvery('SET_EMAIL_RESERVE', ({ value }) => emailReserve(value))
    yield takeEvery('SET_EMAIL_LIBERATION', ({ value }) => emailLiberation(value))
    yield takeEvery('SET_PASSWORD', ({ password }) => setPassword(password))
    yield takeEvery('SELECT_CRENEAU', ({ hDeb, hFin, agenceId }) => selectCreneau(hDeb, hFin, agenceId))
    yield takeEvery('GET_MY_LESSONS', () => getMyLessons())
    yield takeEvery('GET_MY_EXAMS', () => getMyExams())
    yield takeEvery('RESET_PWD', ({ email }) => reset(email))
}